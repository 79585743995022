<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="FAQ" slot="component">
      <div class="FAQ-head">
        <p>{{$t('frequently asked questions')}}</p>
        <!-- <div class="FAQ-head-btn-contain">
          <button class="button-primary btn" @click="contactSupport">CONTACT SUPPORT</button>
        </div>-->
      </div>
      <div class="FAQ-box">
        <Loading v-if="showLoader" class="vlive-loader"></Loading>
        <div class="FAQ-container" v-else>
          <div
            class="vlive-faqs"
            v-for="(faq, i) in dummyfaqs"
            :key="i"
            :id="`faq-data-${i}`"
            :class="isActive(i)"
            :data-action="getDataAction(i)"
          >
            <div class="vlive-faq-questions" @click="toggleAnswers(i)">
              <p
                class="questions"
                :style="[localDisplayLang === 'ara' ? {'float':'right'} : {'float':'left'}]"
              >{{ faq.faqquestion }}</p>
              <div
                class="faq-collapse"
                :style="[localDisplayLang === 'ara' ? {'float':'left'} : {'float':'right'}]"
              >
                <img :src="getCollapseIcon(i)" class="collapse-icon" :id="`collapse-icon-${i}`" />
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="vlive-faq-answers" :id="`faq-ans-${i}`">
              <p class="answers">{{ faq.faqanswer }}</p>
            </div>
          </div>
          <!-- <div class="FAQ-foot">
            <div class="FAQ-foot-btn-contain">
              <button
                class="button-primary btn"
                :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
                @click="contactSupport"
              >CONTACT SUPPORT</button>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";
export default {
  props: ["closePopup"],
  data() {
    return {
      showLoader: true,
      width: "55%",
      margin: "5% auto",
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      radiusType: "full",
      dummyfaqs: [], //later it will store the API JSON Data
      upArrow: require("@/assets/icons/down_arrow.svg"),
      downArrow: require("@/assets/icons/up_arrow.svg"),
      providerUniqueId: _providerId,
      localDisplayLang: null
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "getRtl"])
  },
  methods: {
    contactSupport() {
      // eventBus.$emit("show-contact-support", true);
      this.closePopup();
      document.getElementById("body").style.overflow = "hidden";
      if (!this.subscriberid) {
        let payload = {
          state: true,
          formType: "lookup"
        };
        eventBus.$emit("authPopup", payload);
      } else {
        eventBus.$emit("create-ticket-popup", true);
      }
    },
    toggleAnswers(index) {
      let ques = document.getElementsByClassName("vlive-faqs");
      if (ques.length > 0) {
        for (let i = 0; i < ques.length; i++) {
          if (i !== index) {
            ques[i].classList.remove("active");
            let clickedQues = document.getElementById(`faq-data-${i}`);
            // let action = clickedQues.getAttribute("data-action");
            let collapseIcon = document.getElementById(`collapse-icon-${i}`);
            clickedQues.classList.remove("active");
            clickedQues.setAttribute("data-action", "add");
            collapseIcon.src = this.upArrow;
          }
        }
      }
      let clickedQues = document.getElementById(`faq-data-${index}`);
      if (!clickedQues) return;
      let action = clickedQues.getAttribute("data-action");
      let collapseIcon = document.getElementById(`collapse-icon-${index}`);
      if (action == "minus") {
        clickedQues.classList.remove("active");
        clickedQues.setAttribute("data-action", "add");
        collapseIcon.src = this.upArrow;
      } else {
        clickedQues.classList.add("active");
        clickedQues.setAttribute("data-action", "minus");
        collapseIcon.src = this.downArrow;
      }
    },
    getCollapseIcon(index) {
      return this.upArrow;
    },
    getDataAction(index) {
      return "plus";
    },
    isActive(index) {
      return "";
    },
    declareDummyFaqs() {
      // console.log("faq url", this.appConfig.providerDetails.faqUrl);
      fetch(
        this.localDisplayLang === "ara"
          ? this.appConfig.providerDetails.faqUrl.ar
          : this.appConfig.providerDetails.faqUrl.default,
        {
          method: "GET"
        }
      )
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(response => {
          // console.log("response faq", response.faqdata);
          this.dummyfaqs = response.faqdata;
          this.showLoader = false;
        });
    }
  },
  mounted() {
    this.declareDummyFaqs();
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", data => {
      this.localDisplayLang = data;
    });
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      )
    // ArrowFaq: () =>
    //   import(
    //     /* webpackChunkName: "arrowsvg" */ "@/components/SvgImages/ArrowFaq.vue"
    //   )
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./Faq.scss"
</style>
